import React, { useState }  from "react"

const ProductCats = ({ productCat }) => {

   console.log('term title', productCat[0].products.nodes[0].productTableDetails);

   const [clicked, setClicked] = useState(false);

   function openDetails(index) {
      setClicked(clicked === index ? -1 : index);
   }

   function formatURL(url) {
      var newURL = url.replace(/^.*\/\/[^\/]+/, '');
      return newURL;
   }


  return (
    <section className="content">
       <div className="eighty-spacer"></div>
       <div className="content-block">
    {productCat[0].products.nodes.length
      ? productCat[0].products.nodes.map((prod,index)  => (
        <div role="button" key={index} tabIndex={0} className={`${clicked === index ? 'product-listing-container is-open' : 'product-listing-container'}`} onClick={() => openDetails(index)} onKeyDown={() => openDetails(index)}>
            <div className="product-listing-holder clearfix" >
              <div className="product-listing-title-container clearfix">
                <div className="product-listing-title-holder">
                  <strong><span dangerouslySetInnerHTML={{ __html: prod.title }}/></strong>
                </div>
                <div className="product-listing-download-holder hide-on-responsive">
                  <a href={formatURL(prod.productFigureDetails.productPdfDownload.mediaItemUrl)} target="_blank" rel="noopener noreferrer">
                     <div>Download <span>Summary PDF</span></div>
                  </a>
                </div>
              </div>
              <div className="product-listing-stat-holder">
                 <ul>
                    <li>
                       <div className="product-listing-label-holder">
                          AER
                       </div>

                       <div className="product-listing-stat-val-holder">
                          {prod.productFigureDetails.aer}&#37;
                       </div>
                    </li>
                    <li>
                       <div className="product-listing-label-holder">
                          Gross
                       </div>

                       <div className="product-listing-stat-val-holder">
                          {prod.productFigureDetails.gross}&#37;
                       </div>
                    </li>
                    <li>
                       <div className="product-listing-label-holder">
                          Min investment
                       </div>

                       <div className="product-listing-stat-val-holder">
                        &pound;{prod.productFigureDetails.minInvestment}                           </div>
                    </li>
                    <li>
                       <div className="product-listing-label-holder">
                          Max investment
                       </div>

                       <div className="product-listing-stat-val-holder">
                        &pound;{prod.productFigureDetails.maxInvestment}                      </div>
                    </li>
                 </ul>
              </div>
              <div className="product-listing-button-wrapper">
                 <div key={`item-${index}`} className="product-listing-button-status-holder hide-on-responsive clicked">{`${clicked === index ? 'Close details' : 'More details'}`}</div>
              </div>
            </div>
            <div className={`${clicked === index ? 'product-listing-dropdown-holder open' : 'product-listing-dropdown-holder'}`} >
                      <div className="product-list-dropdown-inner-table-holder">
                     <div className="product-list-dropdown-table">
                        <ul>
                           <li>
                              <span>
                                 {prod.productTableDetails.termTitle ? prod.productTableDetails.termTitle : "Term" }
                              </span>
                              <div className="product-list-drop-val-holder">
                                 {prod.productTableDetails.term}                                  </div>
                           </li>
                           <li>
                              <span>
                                 Interest paid
                              </span>
                              <div className="product-list-drop-val-holder">
                                 {prod.productTableDetails.interestPaid}                                  </div>
                           </li>
                           <li>
                              <span>
                                 How to apply
                              </span>
                              <div className="product-list-drop-val-holder">
                                 {prod.productTableDetails.howToApply}                                  </div>
                           </li>
                           <li>
                              <span>
                                 Withdrawals
                              </span>
                              <div className="product-list-drop-val-holder">
                                 {prod.productTableDetails.withdrawals}                                 </div>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="product-listing-dropdown-inner-holder">
                      <div className="product-listing-dropdown-intro-holder" dangerouslySetInnerHTML={{ __html: prod.content }}/>

                     <div className="product-choice-holder clearfix">
                            <div className="product-choice-half-holder">
                            <div className="product-choice-label-holder" dangerouslySetInnerHTML={{ __html: prod.rightForYouGroup.rightChoiceTitle }}/>

                            <div className="product-right-choice-text-holder right-choice" dangerouslySetInnerHTML={{ __html: prod.rightForYouGroup.theRightChoiceForYou }}/>


                           </div>
                          <div className="product-choice-half-holder">

                          <div className="product-choice-label-holder" dangerouslySetInnerHTML={{ __html: prod.rightForYouGroup.notTheRightChoiceTitle }}/>

                          <div className="product-wrong-choice-text-holder" dangerouslySetInnerHTML={{ __html: prod.rightForYouGroup.notTheRightChoiceForYou }}/>

                           </div>
                                             </div>

                                             <div className="product-listing-dropdown-links-holder">
                        <ul>

                  {prod.productFigureDetails.productCtaLinks && prod.productFigureDetails.productCtaLinks.map((description,index) => (
                  <li key={index}><a href={description.productCtaUrl}>{description.productCtaLinkTitle}</a></li>
                  ))}

                                                      </ul>
                     </div>
                                             <div className="product-listing-download-holder show-on-responsive">
                           <a href={formatURL(prod.productFigureDetails.productPdfDownload.mediaItemUrl)} target="_blank" rel="noopener noreferrer">
                              <span></span> Download Summary Box
                           </a>
                        </div>
                                       </div>
                  </div>
                  <div className="product-listing-button-status-holder mob-trigger-open show-on-responsive">More details</div>
                  </div>


      ))
    : null}
</div>
 <div className="eighty-spacer"></div>
</section>
  )
}

export default ProductCats
