import React from "react"
import {
   Accordion,
   AccordionItem,
   AccordionButton,
   AccordionPanel,
 } from "@reach/accordion"
 import "@reach/accordion/styles.css"

function PreviousInterestRateRepeater({ sectionTitle, previousInterestRatesRepeater }){

console.log('section title', sectionTitle, 'previous interest rate', previousInterestRatesRepeater)
return (
   <section className="content very-light-blue-background">
      <div className="content-block clearfix">
         <div className="eighty-spacer"></div>
         <div className="centered-content-container">
            <div className="centered-intro-title-holder">
               <h1>{ sectionTitle ? sectionTitle : "Previous Interest Rates" }</h1>
            </div>
         </div>
         <div className="twenty-spacer"></div>         

         <Accordion collapsible>
            <div className="pir-container">
               {previousInterestRatesRepeater.length > 0 && 
                     previousInterestRatesRepeater.map((group, index) => {
                        return (
                           <AccordionItem key={index}>
                              <ul>
                                 <li>
                                    <AccordionButton>
                                       <div className="c-faq-question-holder current">
                                          <div className="question-text">
                                             Previous Interest Rates
                                          </div>
                                          <span className="arrow"></span>
                                       </div>
                                    </AccordionButton>
                                    <AccordionPanel>
                                          {group?.subGroup.length > 0 &&
                                             <ul className="c-faw-answer-container">
                                                <li>
                                                   <div className="interest-rate-headings savings-txt-colour">
                                                      <div class="interest-rate-row-half">
                                                         Account
                                                      </div>
                                                      <div class="interest-rate-row-small">
                                                         Current Rate
                                                      </div>
                                                      <div class="interest-rate-row-small">
                                                         Current Rate Since
                                                      </div> 
                                                      <div class="interest-rate-row-small">
                                                         Available To New Customers
                                                      </div> 
                                                   </div>
                                                   {group?.subGroup.map((subGroup, index) => {                                                         
                                                      return (
                                                         <div className="interest-rate-row" key={index}>
                                                               <div class="interest-rate-row-half">
                                                                  {subGroup.account}
                                                               </div>
                                                               <div className="interest-rate-row-small">
                                                                  <div className="mobile-interest-headings">
                                                                     Gross Interest
                                                                  </div>
                                                                  {subGroup.availableToNewCustomers}
                                                               </div>
                                                               <div className="interest-rate-row-small">
                                                                  <div className="mobile-interest-headings">
                                                                     Gross AER
                                                                  </div>
                                                                  {subGroup.currentRate}
                                                               </div>

                                                               <div className="interest-rate-row-small">
                                                                  <div className="mobile-interest-headings">
                                                                     Net Interest
                                                                  </div>
                                                                  {subGroup.currentRateSince}
                                                               </div>
                                                         </div>
                                                      )
                                                   })}                                                   
                                                </li>
                                             </ul>     
                                          }
                                    </AccordionPanel>                                               
                                 </li>
                              </ul>
                           </AccordionItem>
                        )
                     }
                  )
               }
            </div>
         </Accordion>                  
      </div>
      <div className="eighty-spacer"></div>
   </section>
 )
}


export default PreviousInterestRateRepeater
